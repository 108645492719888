/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@3.4.1/dist/css/bootstrap.min.css
 * - /npm/jasny-bootstrap@3.1.3/dist/css/jasny-bootstrap.min.css
 * - /npm/select2@4.0.13/dist/css/select2.min.css
 * - /npm/eonasdan-bootstrap-datetimepicker@4.17.49/build/css/bootstrap-datetimepicker.min.css
 * - /npm/bootstrap-daterangepicker@3.1.0/daterangepicker.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
